import "./App.css";
import React, { lazy, Suspense, useEffect, useState } from "react";
import NotFound from "./screens/notFound";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Loader from "../src/components/loader";
import PreLoader from "./layouts/l001/pre-loader";
import rotatePhone from "../src/assets/rotate-your-screen.gif";
import { Helmet } from "react-helmet";

function App() {
  //
  const [loadTimer, setLoadTimer] = useState(false);
  const Home = lazy(() => import("./screens/home"));

  let curPage = window.location.pathname;

  useEffect(() => {
    setTimeout(() => {
      setLoadTimer(true);
    }, 5000);
  }, []);

  return (
    <>
      <Helmet>
        {curPage.includes("RR310") ? (
          <script type="text/javascript">
            {`
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-NNRS5DKH');
      `}
          </script>
        ) : (
          curPage.includes("RTR-310") && (
            <script type="text/javascript">
              {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-K6BLKFP7');
        `}
            </script>
          )
        )}
      </Helmet>

      <div id="wrapper">
        <Suspense
          fallback={
            <>
              {curPage.includes("RR310") && loadTimer ? (
                <PreLoader />
              ) : (
                <Loader />
              )}
            </>
          }
        >
          <Router>
            <Routes>
              <Route exact path="/products/:route" element={<Home />}></Route>
              <Route path="/products" element={<NotFound />} />
              <Route path="*" element={<Navigate replace to="/products" />} />
            </Routes>
          </Router>
        </Suspense>
      </div>
      <div id="warning-message">
        <Loader loaderGif={rotatePhone} />
        <p className="mediumFont">
          Our website is exclusively designed for landscape mode. Please rotate
          your device to landscape orientation to seamlessly experience the 3D
          view of the bike.
        </p>
      </div>
    </>
  );
}

export default App;
